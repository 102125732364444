// Core
import React from "react"
import Typed from "react-typed"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

// Layout
import { Layout } from "../layouts"

// Components
import { Wrapper, Banner, Slider, Seo } from "../components"

// Icons
import { BsArrowRightShort } from "react-icons/bs"
import BurgerKingIcon from "../assets/icons/brand-burger-king.inline.svg"
import PopeyesIcon from "../assets/icons/brand-popeyes.inline.svg"
import SonicIcon from "../assets/icons/sonic.inline.svg"
import CaptureIcon from "../assets/icons/how-capture.inline.svg"
import UnderstandIcon from "../assets/icons/how-understand.inline.svg"
import DeliverIcon from "../assets/icons/how-deliver.inline.svg"
import MetricsLeftIcon from "../assets/icons/metrics-left.inline.svg"
import MetricsRightIcon from "../assets/icons/metrics-right.inline.svg"

const HomePage = () => (
  <Layout>
    <Seo title="Outloud.ai: New AI standard for drive-thrus" />
    <section className="home-hero">
      <div className="home-hero__row">
        <h1 className="home-hero__title">
          Outloud.ai: New AI standard for drive-thrus
        </h1>
        <h2 className="home-hero__subtitle">
          <Typed
            strings={[
              "When is my restaurant not taking orders?",
              "Do my cashiers upsell high-margin products?",
              "How can I increase the speed of service?",
            ]}
            typeSpeed={40}
            backSpeed={30}
            backDelay={2500}
            smartBackspace
            loop
          />
        </h2>
        <p className="home-hero__description">
          We have handled 40 million orders in hundreds of restaurants.
        </p>
        <Link to="/demo">
          <button className="home-hero__button">
            Get Started{" "}
            <BsArrowRightShort className="home-hero__button--arrow" />
          </button>
        </Link>
      </div>

      <section className="home-brands">
        <p className="home-brands__title">Trusted by leading brands:</p>
        <ul className="home-brands__list">
          <li className="home-brands__item">
            <SonicIcon className="home-brands__icon--sonic" />
          </li>
          <li className="home-brands__item">
            <BurgerKingIcon className="home-brands__icon--burger" />
          </li>
          <li className="home-brands__item">
            <PopeyesIcon className="home-brands__icon--popeyes" />
          </li>
        </ul>
      </section>
    </section>

    <section className="home-features">
      <Wrapper>
        <div className="home-features__row">
          <div className="home-features__wrapper">
            <StaticImage
              src="../assets/feature-llm.png"
              alt="Customer satisfaction"
              quality="100"
              width={350}
              placeholder="blurred"
            />
          </div>

          <section className="home-features__description">
            <h3 className="home-features__title">Custom LLMs for operations</h3>
            <h2 className="home-features__subtitle">
              Get notified in real-time about ops issues
            </h2>

            <p className="home-features__text">
              We've fine-tuned the latest Large Language Models to detect every
              significant operational issue such as stores not taking orders,
              equipment malfunction, product shortages, etc.
            </p>
          </section>
        </div>

        <div className="home-features__row home-features__row--reverse">
          <StaticImage
            className="home-features__img"
            src="../assets/feature-upsell.png"
            alt="Suggestive upsell"
            quality="90"
            layout="fullWidth"
            placeholder="blurred"
          />
          <section className="home-features__description">
            <h3 className="home-features__title">
              Next-gen revenue intelligence
            </h3>
            <h2 className="home-features__subtitle">
              Increase your check by 15% with upsell
            </h2>

            <p className="home-features__text">
              We use advanced AI pipeline to understand when, what, and how your
              employees upsell.
            </p>
          </section>
        </div>

        <div className="home-features__row">
          <StaticImage
            className="home-features__img"
            src="../assets/feature-ops.png"
            alt="Ops"
            quality="90"
            layout="fullWidth"
            placeholder="blurred"
          />
          <section className="home-features__description">
            <h3 className="home-features__title">
              Voice AI for smart order processing
            </h3>
            <h2 className="home-features__subtitle">
              Reduce your total SoS to less than 3 minutes
            </h2>

            <p className="home-features__text">
              Our models are trained on thousands hours of data to help your
              team boost crucial metrics like speed of service.
            </p>
          </section>
        </div>
      </Wrapper>
    </section>

    <section className="home-how">
      <Wrapper>
        <h2 className="home-how__main--title">How it works</h2>

        <div className="home-how__list">
          <section className="home-how__item">
            <div className="home-how__icon--wrapper home-how__icon--capture">
              <CaptureIcon className="home-how__icon" />
            </div>
            <h3 className="home-how__title">Record</h3>
            <p className="home-how__subtitle">Employee/guest order-taking</p>
          </section>

          <section className="home-how__item">
            <div className="home-how__icon--wrapper home-how__icon--understand">
              <UnderstandIcon className="home-how__icon" />
            </div>
            <h3 className="home-how__title">Analyze</h3>
            <p className="home-how__subtitle">Each order in real-time</p>
          </section>

          <section className="home-how__item">
            <div className="home-how__icon--wrapper home-how__icon--deliver">
              <DeliverIcon className="home-how__icon" />
            </div>
            <h3 className="home-how__title">Alert</h3>
            <p className="home-how__subtitle">
              When restaurants have ops issues
            </p>
          </section>
        </div>
      </Wrapper>
    </section>

    <section className="home-metrics">
      <MetricsLeftIcon className="home-metrics__icon home-metrics__icon--left" />
      <MetricsRightIcon className="home-metrics__icon home-metrics__icon--right" />
      <Wrapper>
        <div className="home-metrics__list">
          <div className="home-metrics__item">
            <span className="home-metrics__title">100,000</span>
            <span className="home-metrics__subtitle">
              Orders processed daily
            </span>
          </div>
          <div className="home-metrics__item">
            <span className="home-metrics__title">$75,000</span>
            <span className="home-metrics__subtitle">
              Increase in gross profit per store
            </span>
          </div>
          <div className="home-metrics__item">
            <span className="home-metrics__title">20+</span>
            <span className="home-metrics__subtitle">
              States in our operation network
            </span>
          </div>
        </div>
      </Wrapper>
    </section>

    <section className="home-feedback">
      <Wrapper>
        <div className="home-feedback__row">
          <section className="home-feedback__description">
            <span className="home-feedback__subtitle">Why us?</span>
            <h2 className="home-feedback__title">
              Helping our clients succeed
            </h2>
            <p className="home-feedback__text">
              Our users love Outloud. Here's what they are saying
            </p>
          </section>
          <div className="home-feedback__slider">
            <Slider />
          </div>
        </div>
      </Wrapper>
    </section>

    <Banner />
  </Layout>
)

export default HomePage
